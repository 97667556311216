/**
 * This contains lists of fields and certain functions used in the student profile.
 */

export const downloadAreas = [
    'Student Details', 'Current Year Summary', 'Attendance', 'Behavior', 
    'Credits', 'Postsecondary', 'College', 'Military', 'Workforce', 'Current Year Courses', 
    // 'Past Courses', 
    'Cumulative Summary', 'Activities'
];

export const PSTareas = ['Postsecondary', 'College', 'Military', 'Workforce'];

export const profileFields = {
    studentDetails: ['currentGradeLevel', 'cohort', 'gender', 'raceAndEthnicity.race', 'englishLearner', 'specialEducation'],
    attendance: ['Q1Absences', 'Q2Absences', 'Q3Absences', 'Q4Absences', 'ADA'],
    behavior: ['Q1Suspensions', 'Q2Suspensions', 'Q3Suspensions', 'Q4Suspensions', 'suspensions'],
    credit: ['g8Credits', 'g9Credits', 'g10Credits', 'g11Credits', 'g12Credits', 'creditsPredicted'],
    ps: ['counselorName', 'plans', 'alternatePlans', 'SATScores.EBRW', 'SATScores.Math', 'FAFSAStatus', 'FAFSAStateStatus', 'applicationEssay'],
    college: [
        'collegeApps', '_collegeAppsSubmitted', 'intendedMajor', 'collegeCommitment', 'navianceCollegeList',
        'studentRequestedRecs', 'teacherSubmittedRecs', 'immunizationForm'
    ],
    military: ['FAFSAStatus', 'ASVAB', 'militaryBranchCommitment', 'militaryBranchInterest', 'militaryRecruiter'],
    workforce: ['collegeApps', 'careerInventory', 'jobApplications', 'resume', 'workPermit', 'tradeProgramEnrollment'],
};

export const getAreasToShow = ({getOnTrackFields, notes, tasks, checkins}) => [
    {
        title: 'Student Details',
        fieldList: profileFields.studentDetails,
        type: 'FieldArea',
        columns: 2
    },
    {
        title: 'Current Year Summary',
        fieldList: getOnTrackFields(),
        type: 'CardArea',
        columns: 6
    },
    {
        title: 'Attendance',
        fieldList: profileFields.attendance,
        type: 'CardArea',
        columns: 5
    },
    {
        title: 'Behavior',
        fieldList: profileFields.behavior,
        type: 'CardArea',
        columns: 5
    },
    {
        title: 'Credits',
        fieldList: profileFields.credit,
        type: 'CardArea',
        columns: 6
    },
    {
        title: 'Postsecondary',
        fieldList: profileFields.ps,
        type: 'CardArea',
        columns: 4
    },
    {
        title: 'College',
        fieldList: profileFields.college,
        type: 'CardArea',
        columns: 4
    },
    {
        title: 'Military',
        fieldList: profileFields.military,
        type: 'CardArea',
        columns: 5
    },
    {
        title: 'Workforce',
        fieldList: profileFields.workforce,
        type: 'CardArea',
        columns: 6
    },
    {
        title: 'Current Year Courses',
        type: 'Courses',
    },
    // {
    //     title: 'Past Courses',
    //     type: 'PastCourses',
    // },
    {
        title: 'Cumulative Summary',
        type: 'CareerSummary',
    },
    {
        title: 'Activities',
        type: 'Activities',
        activities: {notes, tasks, checkins}
    },
];
