/**
    Shows list of activities (notes, tasks, check-ins). Used in student profile.
*/
<template>
    <div
        class="activity-area-outer"
        v-for="(activity, z) in activities" :key="z"
    >
        <div class="activity-area-inner">
            <div class="activity-title">
                {{ activity.pluralTitle }}
                <span 
                    class="column-select-button nudge-down"
                    data-cy="add-activity-button"
                    @mouseover="showTooltip(`Add ${activity.title}`)"
                    @mouseleave="showTooltip('')"
                    @click="ee.emit('addTask', {mode: activity.pluralTitle, student: this.selectedStudent})"
                >
                    <span class="material-icons-outlined column-select-icon">
                        add
                    </span>
                </span>
            </div>
            <div class="card-holder">
                <div
                    v-for="(note, i) in activity.getItems()" :key="i" 
                    :class="`${activity.class} card`" 
                    data-cy="activity-area-card"
                    @click="ee.emit('editTask', note)"
                >
                    {{ note.title }}
                    <div class="category-area">
                        <span v-for="(category, j) in note.categories" :key="j">
                            <div class="category-bubble" :style="getCategoryStyle(category)">
                                {{ category.title }}
                            </div>
                        </span>
                    </div>
                    <div v-if="activity.title === 'Check-in'" class="interaction-area">
                        <div class="due-date-area" v-html="formatDate(note.date)"></div>
                    </div>
                    <div v-if="activity.title === 'Task'" class="interaction-area">
                        <div class="check-area" @click.stop="toggleComplete(note)">
                            <span class="material-icons-outlined">{{note.completed ? 'check_circle' : 'circle'}}</span>&nbsp;
                            <span class="completed-message">Completed</span>
                        </div>
                        <div class="due-date-area" v-html="formatDueDate(note.dueDate)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { blackOrWhite } from '@/functions/utils.js';
import { formatDueDate, formatDate } from '@/functions/activities.js';

export default {
    props: {
        checkins: Array,
        tasks: Array,
        notes: Array,
        selectedStudent: Object
    },
    data() {
        return {
            activities: this.getAvailableActivities()
        }
    },
    methods: {
        formatDueDate,
        formatDate,
        blackOrWhite,
        getAvailableActivities() {
            const result = [
                {
                    title: 'Check-in',
                    pluralTitle: 'Check-ins',
                    class: 'checkin',
                    getItems: () => this.checkins,
                    OTCOnly: true
                },
                {
                    title: 'Task',
                    pluralTitle: 'Tasks',
                    class: 'task',
                    getItems: () => this.tasks,
                    OTCOnly: false
                },
                {
                    title: 'Note',
                    pluralTitle: 'Notes',
                    class: 'note',
                    getItems: () => this.notes,
                    OTCOnly: false
                },
            ]
            return result.filter(element => {
                return this.$user.hasPower('viewOTCEvents') ? true : !element.OTCOnly;
            })
        },
        toggleComplete(task) {
            task.completed = !task.completed;
            this.fb.messages.update(task.docID, task);
        },
        getCategoryStyle(category) {
            return `background-color: ${category.color}; color: ${this.blackOrWhite(category.color)};`
        },
    }
}
</script>

<style lang="scss">
@import '../../assets/styles/colors.scss';
.activity-container {
    display: flex;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
    .activity-area-outer {
        width: 33%;
        @media (max-width: 1200px) {
            width: 100%;
        }
        padding: 10px;
        .activity-area-inner {
            background-color: var(--color-contrast-lower);
        }
        .nudge-down {
            position: relative;
            top: 2px;
        }
    }
    .activity-title {
        font-weight: bold;
        text-align: center;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
    }
    .card-holder {
        padding: 10px;
    }
    .card {
        &:hover {
            box-shadow: 0px 0px 10px var(--color-contrast-high);
            cursor: pointer;
        }
        background-color: var(--color-bg);
        margin-bottom: 10px;
        padding: 10px;
        .category-area {
            margin-top: 10px;
        }
        .category-bubble {
            padding: 5px;
            border-radius: 5px;
            margin-right: 5px;
            display: inline-block;
        }
        .date-overdue {
            background-color: var(--color-warning);
            color: var(--color-bg);
            padding: 3px;
            border-radius: 5px;
        }
        .date-approaching {
            background-color: var(--color-info);        
            color: var(--color-bg);
            padding: 3px;
            border-radius: 5px;
        }
        .interaction-area {
            display: flex;
            padding-top:10px;
            .check-area {
                flex: 50%;
                .completed-message {
                    position: relative;
                    top: -5px;
                }
            }
        }
    }
}
</style>
