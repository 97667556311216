/**
    This shows the 'counselor view' in grade 11 and 12. It is the legacy version of the student profile.
*/
<template>
    <h1 class="headline-medium contrast-full">{{ selectedStudent.firstName }} {{ selectedStudent.lastName }}</h1>
    <div class="tag-area">
        <span v-for="(tag, index) in store.state.postSecTags" :key="index">
            <span v-if="studentHas(tag)"
                class="badge details-badge"
                :style="'background-color: #' + tag.color + '; color:' + blackOrWhite(tag.color) + ';'"
            >
                {{ tag.title }}
            </span>
        </span>
    </div>
    <table class="counselor-view-table" v-for="(group, index) in getGroupsToShow()" :key="index">
        <tr>
            <th class="group-name">{{ group.name }}</th>
            <th></th>
        </tr>
        <tr v-for="(field, i) in getFieldsToShow(group.fields)" :key="i">
            <td>{{ field.name }}</td>
            <td class="bold-value" :class="getDisplayClass(selectedStudent, field.name)">
                {{ getDisplayValue(selectedStudent, field.name) }}
                <span v-if="isEditable(field)">
                    <span 
                        v-if="$user.hasPower('updateStudents')" 
                        class="material-icons edit-message-button" 
                        @click="launchEdit(field)"
                    >
                        edit
                    </span>
                </span>
            </td>
        </tr>
    </table>
    <div class="activity-area" v-if="logs.length">
        <h3>Activity Log</h3>
        <table data-cy="activity-table" class="activity-table">
            <colgroup>
                <col class="date-col">
                <col class="user-col">
                <col class="update-col">
            </colgroup>
            <tr>
                <th>Date</th>
                <th>User</th>
                <th>Update</th>
            </tr>
            <tr v-for="(log, index) in logs" :key="index">
                <td>{{ log.date }}</td>
                <td>{{ log.author }}</td>
                <td>{{ log.field }} → {{ log.value }}</td>
            </tr>
        </table>
    </div>
</template>

<script>

import { blackOrWhite, getDisplayValue, getValueFromObject, getDisplayClass } from '@/functions/utils.js';

export default {
    name: 'Student Field List',
    data() {
        return {
            fields: this.config.fields.filter(element => element.type !== 'notes' && element.type !== 'tags'),
        }
    },
    props: {
        selectedStudent: Object,
        logs: Array
    },
    methods: {
        studentHas(tag) {
            return this.selectedStudent?.postSecTags?.[0]?.value ? // is there a log entry?
                this.selectedStudent.postSecTags[0].value.includes(tag.docID) ? // does that tag exist in the array?
                    true:
                    false:
                false;
        },
        getDisplayClass(record, fieldName) {
            const field = this.fields.find(element => element.displayName == fieldName);                       
            return getDisplayClass({student: record, field});
        },
        getDisplayValue (record, fieldName) {
            const field = this.fields.find(element => element.displayName == fieldName);
            if (!field?.key) return;
            const value = getValueFromObject(record, field.key);
            return getDisplayValue({value, field});
        },
        getGroupsToShow () {
            return [
                'Student Details', 'Postsecondary', 'Academics', 'College Tasks', 'Workforce/Military Tasks'
            ].map(str => this.config.fieldGroups.find(element => element.name == str));
        },
        getFieldsToShow (fieldList) {
            return fieldList.filter(field => {
                const myField = this.fields.find(element => element.key == field.key);
                if (!myField) return false;
                if (myField.hideInCounselorView) return false;
                return true;
            });
        },
        isEditable (field) {
            // decide if field can be edited
            const myField = this.fields.find(element => element.displayName == field.name);
            if (!myField) return false;
            if (!myField.readOnly) return true;
            return false;
        },
        launchEdit (field) {
            // after user clicks on edit pencil
            const student = this.selectedStudent;
            const myField = this.fields.find(element => element.displayName == field.name);
            if (!myField) return false;

            const key = myField.key;
            if (!key) return;
            
            this.ee.emit('launchEditField', {
                key: key,
                currentValue: getValueFromObject(student, key),
                id: student.studentRISEId,
                studentName: this.selectedStudent._studentName
            });
        },
        blackOrWhite,
    }
}
</script>

<style lang="scss">
// @import '../../assets/styles/colors';

.counselor-view-table {
    .group-name {
        font-size: 2rem;
    }
    .bold-value {
        font-weight: bold;
    }
    td {
        &.warning {
            color: var(--color-warning);
        }
        &.info {
            color: var(--color-info);
        }
        &.success {
            color: var(--color-success);
        }
        &.dataMissing {
            color: var(--color-contrast-medium);
        }
    }
}
</style>
