/**
    Shows cumulative summary (student's past scores in previous years.)
*/
<template>
    <div class="career-summary-area">
        <table class="career-summary-table">
            <tr>
                <th></th>
                <th>Grade 8</th>
                <th>Grade 9</th>
                <th>Grade 10</th>
                <th>Grade 11</th>
                <th>Grade 12</th>
                <th>Cumulative</th>
            </tr>
            <tr
                v-for="(category, index) in categories" :key="index"
            >
                <td>{{ category }}</td>
                <td
                    v-for="(grade, jndex) in grades" :key="jndex" :class="getCellClass({grade, category})"
                >
                    {{ getEntry({grade, category}) }}
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import { getCareerSummaryEntry, getCareerSummaryClass } from '@/functions/utils.js';

export default {
    props: {
        selectedStudent: Object
    },
    data() {
        return {
            grades: [8, 9, 10, 11, 12, 'Cumulative'],
            categories: [
                'GPA', 
                'Credits', 
                'ADA', 
                // 'Suspensions'
            ],
        }
    },
    methods: {
        getEntry({grade, category}) {
            return getCareerSummaryEntry({grade, category, student: this.selectedStudent});
        },
        getCellClass({grade, category}) {
            return 'cell-' + getCareerSummaryClass({grade, category, student: this.selectedStudent});
        }
    }
}
</script>

<style lang="scss">

.career-summary-area {
    padding: 10px;
    table {
        border-collapse: collapse;
        th {
            text-align: center;
        }
        td {
            padding: 5px 0;
            text-align: center;
            &.cell-success {
                background-color: var(--color-success);
                color: var(--color-bg);
            }
            &.cell-gradeB {
                background-color: var(--color-grade-b);
            }
            &.cell-info {
                background-color: var(--color-info);
            }
            &.cell-warning {
                background-color: var(--color-warning);
                color: var(--color-bg);
            }
        }
    }
    tr:nth-child(even){
        background-color: var(--color-contrast-lower);
    }
}
</style>
