/**
 * This contains the functions for building the config file so the PDFMake library can create a PDF of the student profile.
 */

import { 
    getValueFromObject, getDisplayValue, getDisplayClass, getPastCourseDetails, 
    sortPastCourses, getCareerSummaryEntry, getCareerSummaryClass, currentYear, months
} from '@/functions/utils.js';
import { fields } from '@/apps/fields.js';
import variables from '@/assets/styles/colors.scss';

const getRecordValue = ({student, fieldKey}) => {
    const field = fields.find(element => element.key == fieldKey);
    if (!field) return;
    const value = getValueFromObject(student, field.key);
    return getDisplayValue({field, value});
};

const styles = {
    header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'left',
    },
    ribbon: {
        margin: [0, 5, 0, 0],
        fillColor: '#263c7f',
        color: '#ffffff',
        bold: true,
        alignment: 'left',
    },
    fieldArea: {
        margin: [0, 0, 0, 10],
        fillColor: '#e0e0e0',
        fontSize: 10,
    },
    card: {
        fillColor: '#e0e0e0',
        fontSize: 10,
        margin: [0, 5, 0, 5],
        alignment: 'center',
    },
    minicard: {
        fillColor: '#e0e0e0',
        fontSize: 8,
        margin: [0, 5, 0, 5],
        alignment: 'center',
    },
    coursesTable: {
        fontSize: 8,
        margin: [0, 0, 0, 10],
    },
    activityHeader: {
        margin: [0, 5, 0, 5],
        bold: true,
        alignment: 'left',
    },
    activityTableHeader: {
        fontSize: 10,
        fillColor: '#e0e0e0',
        bold: true,
    }
};

const defaultStyle = {
    alignment: 'left',
};

const defaultBorder = false;

const getHeader = text => ({text, style: 'header'});

const getRibbon = text => ({
    style: 'ribbon',
    table: {
        widths: ['*'],
        body: [[text]]
    }
});

const emptyCell = {text: '', fillColor: '#ffffff'};

const get = {};

get.FieldArea = ({student, element}) => {

    const _body = element.fieldList
        .map(key => key ? [
            fields.find(f => f.key == key).displayName,
            {
                text: getRecordValue({student, fieldKey: key}),
                fillColor: '#ffffff',
                bold: true,
            }
        ] : [emptyCell, emptyCell])
        .flat();

    const body = new Array(Math.ceil(_body.length / 4))
        .fill('')
        .map((_, i) => _body.slice(i * 4, (i + 1) * 4));
        
    return {
        style: 'fieldArea',
        layout: {
            defaultBorder: false,
        },
        table: {
            widths: ['*','*','*','*'],
            body
        }
    };
};

//TECH DEBT: this is not accounting for the other color themes for the Data Hub
//Accessibility concerns
const cardStyles = {
    dataMissing: {
        fillColor: variables['defaultcontrastLower'],
        color: '#707070'
    },
    success: {
        fillColor: variables['defaultsuccess'],
        color: '#ffffff'
    },
    info: {
        fillColor: variables['defaultinfo'],
        color: '#000000'
    },
    gradeB: {
        fillColor: variables['defaultgradeB'],
        color: '#000000'
    },
    warning: {
        fillColor: variables['defaultwarning'],
        color: '#ffffff'
    },
    alert: {
        fillColor: variables['defaultalert'],
        color: '#ffffff'
    }
};

const getColor = ({student, fieldKey, colorKey}) => {
    const field = fields.find(f => f.key == fieldKey);
    if (!field) return '#ffffff';
    const style = getDisplayClass({student, field});
    const myDefault = colorKey === 'color' ? '#000000' : '#ffffff';
    return cardStyles[style]?.[colorKey] || myDefault;
};

const getCard = ({student, element, key}) => ({
    style: element.columns > 4 ? 'minicard' : 'card',
    layout: {
        defaultBorder: false,
    },
    table: {
        widths: [400 / element.columns],
        body: [
            [
                {
                    text: {
                        text: fields.find(f => f.key == key).displayName,
                        color: getColor({student, fieldKey: key, colorKey: 'color'})
                    }, 
                    fillColor: getColor({student, fieldKey: key, colorKey: 'fillColor'}),  
                },
            ],
            [
                {
                    text: {
                        text: getRecordValue({student, fieldKey: key}),
                        color: getColor({student, fieldKey: key, colorKey: 'color'})
                    },
                    fillColor: getColor({student, fieldKey: key, colorKey: 'fillColor'}),
                    bold: true,
                },
            ]
        ]
    }
});

get.CardArea = ({student, element}) => {

    const _body = element.fieldList
        .map(key => key ? getCard({student, element, key}) : ' ')
        .flat();

    const body = new Array(Math.ceil(_body.length / element.columns))
        .fill('')
        .map((_, i) => _body.slice(i * element.columns, (i + 1) * element.columns));
        
    return {
        style: 'fieldArea',
        layout: {
            defaultBorder: false,
        },
        table: {
            widths: new Array(element.columns).fill('*'),
            body
        }
    };
};

const markingPeriods = ['Q1', 'Q2', 'S1', 'Q3', 'Q4', 'S2', 'Y1'];


const gradeStyles = {
    A: {
        fillColor: variables['defaultgradeA'],
        color: '#ffffff'
    },
    B: {
        fillColor: variables['defaultgradeB'],
        color: '#000000'
    },
    C: {
        fillColor: variables['defaultgradeC'],
        color: '#000000'
    },
    D: {
        fillColor: variables['defaultgradeD'],
        color: '#ffffff'
    },
    F: {
        fillColor: variables['defaultgradeF'],
        color: '#ffffff'
    },
    I: {
        fillColor: variables['defaultcontrastLow'],
        color: variables['defaultcontrastHigher']
    },
    //This is actually letter grade ME
    M: {
        fillColor: variables['defaultcontrastLow'],
        color: variables['defaultcontrastHigher']
    },
    P: {
        fillColor: variables['defaultcontrastLow'],
        color: variables['defaultcontrastHigher']
    },
};

//TECH DEBT - handle striping of letter grade + or - using regex. We have an letter grade of ME that is length 2.
const getGradeColor = ({letterGrade, colorKey}) => letterGrade ? gradeStyles[letterGrade.slice(0, 1)][colorKey] : '';

get.Courses = ({student, element}) => {

    const courseKeys = student.courses ? Object.keys(student.courses) : []
    const myCourses = courseKeys
        .map(key => student.courses[key])
        .filter(course => course !== undefined)
        .filter(course => course.schoolYear == currentYear)
        .sort((a, b) => a.period > b.period ? 1 : -1)
        .map(course => [
                course.period, course.courseTitle, course.teacher.lastName, course.creditHours, course.currentAbsences.toString(), course.currentTardies.toString(),
            ].concat(markingPeriods.map(period => {
                if (!course.letterGrade[period]) return '';
                return {
                    text: { 
                        text:`${course.letterGrade[period]} (${course.percentageGrade[period]})`,
                        color: getGradeColor({letterGrade: course.letterGrade[period], colorKey: 'color'})
                    }, 
                    fillColor: getGradeColor({letterGrade: course.letterGrade[period], colorKey: 'fillColor'})
                };
            }))
        );

    const headerRow = ['P', 'Course title', 'Teacher', 'Credits', 'A', 'T', 'Q1', 'Q2', 'S1', 'Q3', 'Q4', 'S2', 'Y1']
        .map(str => ({text: str, style: { bold: true }}));

    return {
        style: 'coursesTable',
        layout: {
            defaultBorder: false,
        },
        table: {
            headerRows: 1,
            widths: [27, '*', '*', 30, 10, 10, 27, 27, 27, 27, 27, 27, 27],
            body: [
                headerRow,
                ...myCourses
            ]
        }
    };
};

// get.PastCourses = ({student, element}) => {

//     const courseKeys = student.courses ? Object.keys(student.courses) : []
//     const myCourses = courseKeys
//         .map(key => student.courses[key])
//         .filter(course => course !== undefined)
//         .filter(course => course.schoolYear !== currentYear)
//         .sort((a, b) => sortPastCourses(a, b))
//         .map((course, i) => {
//             const [grade, letterGrade, percentageGrade, credit] = getPastCourseDetails({student, course});

//             return [
//                 course.schoolYear, grade, course.semester, course.creditType[0], course.courseTitle, credit, 
//                 letterGrade ? 
//                     `${letterGrade} (${percentageGrade})` : ''
//             ].map(element => ({
//                 text: element, 
//                 fillColor: i % 2 ? variables['defaultcontrastLower'] : '#ffffff'
//             }));
//         });

//     const headerRow = ['School Year', 'Grade', 'Semester', 'Credit Type', 'Course Title', 'Credit', 'Grade']
//         .map(str => ({text: str, style: { bold: true }}));

//     return {
//         style: 'coursesTable',
//         layout: {
//             defaultBorder: false,
//         },
//         table: {
//             headerRows: 1,
//             widths: [35, 35, 35, 35, '*', 31, 40],
//             body: [
//                 headerRow,
//                 ...myCourses
//             ]
//         }
//     };
// };

get.CareerSummary = ({student, element}) => {
    const headerRow = ['', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12', 'Cumulative']
        .map(str => ({text: str, style: { bold: true }}));

    const grades = [8, 9, 10, 11, 12, 'Cumulative'];
    const categories = ['GPA', 'Credits', 'ADA', 'Suspensions'];

    const entries = new Array(4)
        .fill('')
        .map((e, i) => new Array(7)
            .fill('')
            .map((f, j) => {
                if (j === 0) return categories[i];

                const args = {
                    grade: grades[j - 1], 
                    category: categories[i], 
                    student
                };
                const text = getCareerSummaryEntry(args);
                const style = getCareerSummaryClass(args);
                const fillColor = variables[`default${style}`];
                const color = ['success', 'warning'].some(element => element === style) ? '#ffffff' : '#000000';

                return {text: {text, color}, fillColor};
            })
        );
    
    return {
        style: 'coursesTable',
        layout: {
            defaultBorder: false,
        },
        table: {
            headerRows: 1,
            widths: ['*','*','*','*','*','*','*'],
            body: [
                headerRow,
                ...entries
            ]
        }
    };
};

const getActivityHeader = str => ({text: str, style: 'activityHeader'});

const getActivityTable = ({body, widths}) => ({
    style: 'coursesTable',
    layout: {
        defaultBorder: false,
    },
    table: {
        headerRows: 1,
        widths,
        body
    }
})

const formatDate = value => {
    if (!value) return '(None)';
    const myDate = new Date(typeof value === 'number' ? value : value + 'T12:00:00');
    const month = months[myDate.getMonth()];
    const date = myDate.getDate();
    
    return `${month} ${date}`;
};

get.Activities = ({student, element}) => {

    const noteBody = [
        ['Note Title', 'Categories', 'Created'].map(element => ({text: element, style: 'activityTableHeader'})),
        ...element.activities.notes.map(note => [
            note.title, 
            note.categories.map(cat => cat.title).join(', '),
            formatDate(note.createdDatetime)
        ])
    ];

    const taskBody = [
        ['Task Title', 'Categories', 'Due Date'].map(element => ({text: element, style: 'activityTableHeader'})),
        ...element.activities.tasks.map(task => [
            task.title, 
            task.categories.map(cat => cat.title).join(', '),
            formatDate(task.dueDate)
        ])
    ];

    const checkinBody = [
        ['Check-in Details', 'YDF Categories', 'Check-in Category', 'Length', 'Date'].map(element => ({text: element, style: 'activityTableHeader'})),
        ...element.activities.checkins.map(checkin => [
            checkin.title, 
            checkin.YDFCategories,
            checkin.checkInCategory,
            checkin.length,
            formatDate(checkin.date)
        ])
    ];

    const notes = element.activities.notes.length ? [
        getActivityHeader('Notes'),
        getActivityTable({body: noteBody, widths: ['*', 100, 50]})
    ] : [];

    const tasks = element.activities.tasks.length ? [
        getActivityHeader('Tasks'),
        getActivityTable({body: taskBody, widths: ['*', 100, 50]})
    ] : [];

    const checkins = element.activities.checkins.length ?  [
        getActivityHeader('Check-ins'),
        getActivityTable({body: checkinBody, widths: ['*', 80, 90, 50, 50]})
    ] : [];

    if (![notes, tasks, checkins].some(element => element.length > 0)) return 'No activities to show.'

    return [...notes, ...tasks, ...checkins];
};

export const getDetailsPDF = ({
    student, areasToShow
}) => {
    const title = getHeader(`${student.firstName} ${student.lastName}`);
    const _content = areasToShow
        .map(element => {
            if (element.fieldList) {
                const remainder = element.fieldList.length % element.columns;
                if (remainder > 0) {
                    for (let i = 0; i < element.columns - remainder; i++) {
                        element.fieldList.push('');
                    }    
                }    
            }
            return element;
        })
        .map(element => [
            getRibbon(element.title),
            get[element.type]({student, element})
        ])
        .flat();
    
    const content = [title].concat(_content);

    return {content, styles, defaultBorder, defaultStyle};
};
